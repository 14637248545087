import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import alertIcon from '../../../../assets/alertIcon.png';
import "./LeaveCourtPopup.css"

const LeaveCourtPopup = () => {
    // TODO: make the popup reusable
    // TODO: Remove user from court on click of "Leave Queue"

    const text = {
        header: 'Please remember to leave the court when you are done playing.',
        primaryCta: 'Okay',
        secondaryCta: 'Leave Court'
    }

    return (
        <div>
            <Modal.Body>
                <img src={alertIcon} alt='alert icon' className="popup-alert-icon" />
                <p>{text.header}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button className='primaryCta'>{text.primaryCta}</Button>
                <Button className='secondaryCta'>{text.secondaryCta}</Button>
            </Modal.Footer>

        </div>
    );
}

export default LeaveCourtPopup;