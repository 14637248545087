import React, { useState, useEffect } from "react";
import "./CountdownTimer.css";

const CountdownTimer = ({ progress, updateProgress }) => {
 
  const INITIAL_DEMO_TIME = 5

  const [time, setTime] = useState(INITIAL_DEMO_TIME);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => {
        const newTime = prevTime > 0 ? prevTime - 1 : 0;

        if (newTime === 0) {
          clearInterval(timer); // Clear the interval when time reaches 0
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);

  }, []);

  const calculateProgress = (remainigTime) => {
    const progress = (1 - remainigTime / INITIAL_DEMO_TIME) * 100;
    return Math.max(0, Math.min(100, progress));
  }

  useEffect(() => {
    const newProgress = calculateProgress(time);
    updateProgress(newProgress);
  }, [time, updateProgress])

  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor((time % 3600) % 60);

  const formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return (
    <div className="timer-container">
      <div className="timer">{formattedTime}</div>
    </div>
  );
};

export default CountdownTimer;
