import LocationMap from "./LocationMap/LocationMap";
import { useEffect, useState } from "react";
import LocationHeader from "./LocationHeader/LocationHeader";
import QueueButton from "./LocationMap/QueueFrame/QueueButton";
import "./SecondPage.css";
import axios from "axios";

import { useParams } from "react-router-dom";

const SecondPage = ({ username, playerNum }) => {
  const { locationID } = useParams();
  const userData = {
    userName: username,
    playerNum: playerNum,
  };

  const hasLocationID = locationID !== undefined;

  // store user data in session storage
  if (username !== "" || playerNum !== 0) {
    sessionStorage.setItem("queupUserData", JSON.stringify(userData));
  }

  // fetch park data
  const base_url = process.env.REACT_APP_BASE_URL;
  const courtStatusULR = `${base_url}/api/courts/${locationID}`;
  const [parkData, setParkData] = useState(null);
  const [availableCourts, setAvailableCourts] = useState(null);

  const [currentTraffic, setCurrentTraffic] = useState(0);

  const queupUserData = JSON.parse(sessionStorage.getItem("queupUserData"));

  const fetchParkData = async () => {
    try {
      const response = await axios.get(courtStatusULR);
      setParkData(response.data.courts);
    } catch (error) {
      console.log(">>>> Error fetching courts availability", error);
    }
  };

  useEffect(() => {
    hasLocationID && fetchParkData();
  }, [locationID]);

  useEffect(() => {
    if (parkData === null) return;

    const availableCourts = parkData.filter((court) => !court.occupied);
    setAvailableCourts(availableCourts);

    const occupiedCourts = parkData.filter((court) => court.occupied);

    setCurrentTraffic(
      Math.round((occupiedCourts.length / parkData.length) * 100)
    );
    console.log(">>>> traffic", currentTraffic, "%");
  }, [parkData]);

  // allocate user to court
  const allocateUserToCourt = async () => {
    try {
      console.log(
        "logging the user id stored in session storage",
        sessionStorage.getItem("userID")
      );
      const response = await axios.post(
        `${base_url}/api/queues/${locationID}`,
        {
          username: queupUserData.userName,
          playerNum: queupUserData.playerNum,
          court_id: availableCourts[0].id,
        }
      );
      console.log(response); // testing
      console.log(">>>> res", response.data.user.id);
      sessionStorage.setItem("userID", response.data.user.id);
    } catch (error) {
      console.log(">>>> Error allocating user to court", error);
    }
  };

  const handleUserAllocation = () => {
    console.log(">>>> CLICK");
    const hasUserID = sessionStorage.getItem("userID");
    console.log(hasUserID); //
    !hasUserID &&
      hasLocationID &&
      availableCourts !== null &&
      availableCourts.length > 0 &&
      allocateUserToCourt();
  };

  // states to change button to be selected
  const [selectButtonID, setSelectButtonID] = useState(null);
  const toggleColor = (courtID) => {
    setSelectButtonID((prevCourtID) =>
      prevCourtID === courtID ? null : courtID
    );
  };
  const [disableCourts, setDisableCourts] = useState(false);

  const highlightButton = (courtID) => {
    setSelectButtonID(courtID);
  };

  return (
    <div>
      <LocationHeader
        locationID={locationID}
        currentTraffic={currentTraffic}
      ></LocationHeader>

      <div className="second-page__main">
        <LocationMap
          selectButtonID={selectButtonID}
          toggleColor={toggleColor}
          disableCourts={disableCourts}
        />
        <QueueButton
          className="queue-button"
          addToQueue={handleUserAllocation}
          selectButtonID={selectButtonID}
          disableCourts={disableCourts}
          setDisableCourts={setDisableCourts}
          highlightButton={highlightButton}
        ></QueueButton>
      </div>
    </div>
  );
};
export default SecondPage;
