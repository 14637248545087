import Modal from 'react-bootstrap/Modal';
import LeaveCourtPopup from './LeaveCourtPopup/LeaveCourtPopup'
import LeaveQueuePopup from './LeaveQueuePopup/LeaveQueuePopup';
import "./PopupFrame.css"

const PopupFrame = () => {
    // TODO: Remove user from queue on click of "Leave Queue"
    // TODO: Remove user from court on click of "Leave Court"

    return (
        <div
            className="modal"

        >
            <div className="modal-overlay" ></div>
            <Modal.Dialog>
                <LeaveCourtPopup />
                {/* <LeaveQueuePopup /> */}

            </Modal.Dialog>
        </div>
    );
}

export default PopupFrame;