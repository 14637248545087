import React, { useEffect, useState } from 'react';
import './LocationHeader.css';
import locationNamesDict from "../../../utils/LocationIDsDict.json"

const LocationHeader = ({ locationID, currentTraffic }) => {
  const [courtStatus, setCourtStatus] = useState("low");
  const [imgAlt, setImgAlt] = useState("Default Traffic");
  const locationName = locationNamesDict[locationID]
  const COURT_TRAFFIC = { "high": "/assets/red-ball.svg", "low": "/assets/green-ball.svg", "mid": "/assets/yellow-ball.svg" }
  const MESSAGES = ["While waiting take a quick survey of our tennis app.", "When you are done, please leave queue for next players.", "While waiting take a quick survey of our tennis app."]


  const surveyLink = 'https://docs.google.com/forms/d/e/1FAIpQLSerSZ1K4__DrdQooe9AdIm6Pt4KhKhiKG8M3TMccA0BYQ2qvw/viewform'

  useEffect(() => {
    switch (true) {
      case currentTraffic <= 33:
        setCourtStatus("low");
        setImgAlt("Empty Traffic");

        break;
      case currentTraffic > 33 && currentTraffic <= 66:
        setCourtStatus("mid");
        setImgAlt("Medium Traffic");

        break;
      case currentTraffic > 66:
        setCourtStatus("high");
        setImgAlt("High Traffic");

        break;
      default:

        break;
    }
  }, [currentTraffic])


  return (
    <div className="location-header--container">
      <img className='white-logo' src="/assets/queup-white-logo.svg" alt="Queup Logo" />

      <div className="location-header">
        <div className="location-text">
          <h1 className="location-name">{locationNamesDict[locationID]}</h1>

          <a href={surveyLink} target='_blank' rel="noreferrer" className="location-broadcast">Click here for a short survey.</a>
        </div>
        <div className='location-status'>
          <img className='location-tennisboy' src={COURT_TRAFFIC[courtStatus]} alt={imgAlt} />
          {/* <img className='location-tennisboy' src={"/assets/red-ball.svg"} alt="Medium Traffic" /> */}
          <div className="court-traffic">{courtStatus === "high" ? "busy" : (courtStatus === "mid" ? "not busy" : "empty")}</div>
          {/* <div className="court-traffic">busy</div> */}
        </div>
      </div>

    </div>
  );
};

export default LocationHeader;
