// import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./LocationMap.css";
import QECourtFrame from "./CourtFrame/QECourtFrame";
import SP2CourtFrame from "./CourtFrame/SP2CourtFrame";
import SP1CourtFrame from "./CourtFrame/SP1CourtFrame";
import KTCourtFrame from "./CourtFrame/KTCourtFrame";

const LocationMap = ({ selectButtonID, toggleColor, disableCourts }) => {
  // TODO: when locationID is undefined, return empty page
  const { locationID } = useParams();

  const selectLocationToRender = (locationID) => {
    if (!locationID) {
      return (
        <ul>
          <Link to="/queue/808c1f6c">
            <li>Queen Elizabeth Park</li>
          </Link>
          <Link to="/queue/940a2ff2">
            <li>Stanley Park (Main)</li>
          </Link>
          <Link to="/queue/75915035">
            <li>Stanley Park (Beaver Lake)</li>
          </Link>
          <Link to="/queue/8e3a414e">
            <li>Kitsilano Beach Park</li>
          </Link>
        </ul>
      );
    }
    switch (locationID) {
      case "808c1f6c":
        return <QECourtFrame selectButtonID={selectButtonID} toggleColor={toggleColor} disableCourts={disableCourts} />;
      case "940a2ff2":
        return <SP1CourtFrame selectButtonID={selectButtonID} toggleColor={toggleColor} disableCourts={disableCourts} />;
      case "75915035":
        return <SP2CourtFrame selectButtonID={selectButtonID} toggleColor={toggleColor} disableCourts={disableCourts} />;
      case "8e3a414e":
        return <KTCourtFrame selectButtonID={selectButtonID} toggleColor={toggleColor} disableCourts={disableCourts} />;
      default:
        console.log("wrong path");
    }
  };

  return (
    <>
      <div className="location-frame">
        <p className="location-frame--info">
          Looks like there are no courts available. Please click the button below to join the queue!
        </p>
        <div className="location-frame--background">
          <div className="location-frame--container">
            <div className="location-frame--map">
              {selectLocationToRender(locationID)}
            </div>
            {selectButtonID !== null && (
              <div className="selected-court-box">
                Court {selectButtonID + 1}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationMap;
